/*----------------------------------------------------------------------------*\
  Colors
\*----------------------------------------------------------------------------*/

$red: #f54e42;

$blue: #4281f5;

$green: #a8ec28;

$yellow: #f5c842;

$orange: #f59342;

$purple: #e342f5;

$cyan: #42f5f5;

$turquoise: #42f598;

$gray: #E9ECEF;

$light: #e8e8e8;

$dark: #363636;

$black: black;

$white: white;

// OLD
//
// $blue: #0E95D7;
// $green: #0B9036;
// $red: red;
// $yellow: yellow;
// $orange: orange;
// $purple: purple;

/*----------------------------------------------------------------------------*\
  Breakpoints
\*----------------------------------------------------------------------------*/

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
 
// Small tablets (portrait view)
$screen-md-min: 768px;
 
// Tablets and small desktops
$screen-lg-min: 992px;
 
// Large tablets and desktops
$screen-xl-min: 1200px;