#header {
  padding: 0.1em;
  margin-bottom: 2rem;
}

#app {
  text-align: center;
  height: 80%;
}

#footer {
  padding: 0.1em;
  margin-top: 2rem;
}